<template>
  <div class="inline-block">
    <color-text-btn :loading="loading" @click="to">分配分销商</color-text-btn>

    <DistributionDialog
      type="pictureDistributor"
      :visible.sync="dialogVisible"
      :info="data"
      :resetInfiniteScrollPage="{ pageIndex: 1, pageSize: 24 }"
      :resetMergeData="resetMergeData"
      v-model="value"
      v-if="showDistributionDialog"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import DistributionDialog from '@/views/components/distributionDialog'
import { commonEheaderMixin } from '@/mixins'
import { getPictureGroupDetail } from '@/api/image/pictureGroupApi'

export default {
  mixins: [commonEheaderMixin],
  components: { DistributionDialog },

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      value: [],
      showDistributionDialog: false,
      resetMergeData: {
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      }
    }
  },

  watch: {
    dialogVisible(newVal) {
      if (newVal) return
      this.$nextTick(() => {
        this.showDistributionDialog = false
      })
    }
  },

  methods: {
    async to() {
      this.showDistributionDialog = true
      const [err, data] = await this.getPictureGroupDetail()
      if (err) return
      this.value = data.userIdList || []
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },

    async getPictureGroupDetail() {
      const { id } = this.data
      this.loading = true
      try {
        const { code, detail } = await getPictureGroupDetail({ id })
        return [!$SUC({ code }), detail || {}]
      } catch {
        return [true, {}]
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
